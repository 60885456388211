import getEnv from '../../services/util/getEnv';

/**
 * Api constants file
 *
 * @package   src/constants
 * @author    WangTuring <wangwang@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */
// const dotenv = require('dotenv');
// dotenv.config();

const ENV = getEnv("REACT_APP_ENV");
const DEV_BASE_URL = getEnv("REACT_APP_DEV_BASE_URL");
const LIVE_BASE_URL = getEnv("REACT_APP_LIVE_BASE_URL");
const REACT_APP_DEVELOPER_SERVICE_API = getEnv("REACT_APP_DEVELOPER_SERVICE_API")

const ApiConstant = {
  /**
   * Api current base url
   */
  BASE_URL: ENV === 'development' ? DEV_BASE_URL : LIVE_BASE_URL,

  /**
   * Api backend base url for challenge dashboard page
   */
  CHALLENGE_BASE_URL: getEnv("REACT_APP_CHALLENGE_BASE_URL"),

  /**
   * Developer Service Base API URL
   */
  REACT_APP_DEVELOPER_SERVICE_API: REACT_APP_DEVELOPER_SERVICE_API,

  /**
   * Source path that the user published for challenge test
   */
  CHALLENGE_SOURCE_PATH: getEnv("REACT_APP_CHALLENGE_SOURCE_PATH"),

  /**
   * Source path for challenge log file
   */
  CHALLENGE_LOG_FILE_PATH: getEnv("REACT_APP_CHALLENGE_SOURCE_PATH"),

  /**
   * Api to add agent information in landingpage
   */
  ADD_AGENT_INFO: 'landingpage/add-agent-info',

  /**
   * Api to add agent data in landingpage
   */
  ADD_AGENT_DATA: 'landingpage/add-agent-data',

  /**
   * Api to save javascript test type in test choice page
   */
  SAVE_JAVASCRIPT_TEST_TYPE: 'board/save-javascript-test-type',

  /**
   * Api to save selected language in test choice page
   */
  SAVE_SELECTED_LANGUAGE: 'board/save-selected-language',

  /**
   * Api to save test result
   */
  SAVE_TEST_RESULT: 'board/save-test-result',

  /**
   * Api to save test response time
   */
  SAVE_TEST_RESPONSE_TIME: 'board/save-test-response-time',

  /**
   * Api to register user account
   */
  SIGNUP: 'auth/signup',

  /**
   * Api to login
   */
  SIGNIN: 'auth/login',

  /**
   * Logging for signup or login with social
   */
  LOG_FOR_SOCIAL: 'auth/log-for-social',

  /**
   * Api to login with google account
   */
  SIGNIN_GOOGLE: 'social-auth/google/login',

  /**
   * Api to save google account data
   */
  SAVE_GOOGLE: 'social-auth/google/save',

  /**
   * Api to login
   */
  SIGNIN_FACEBOOK: 'social-auth/facebook/login',

  /**
   * Api to save facebook account data
   */
  SAVE_FACEBOOK: 'social-auth/facebook/save',

  /**
   * Api to save user entrance history
   */
  SAVE_USER_ENTRANCE: 'auth/store-entrance-data',

  /**
   * Api to save remote experience info
   */
  SAVE_REMOTE_EXP_INFO: 'submit/save-remote-exp-info',

  /**
   * Api to save English experience info
   */
  SAVE_ENGLISH_EXP_INFO: 'submit/save-user-exp-info',

  /**
   * Api to fetch remote experience info
   */
  GET_REMOTE_EXP_INFO: 'submit/get-remote-exp-info',

  /**
   * Api to save profile info
   */
  SAVE_PROFILE_INFO: 'submit/save-profile-info',

  /**
   * Api to fetch profile info
   */
  GET_PROFILE_INFO: 'submit/get-profile-info',

  /**
   * Api to save not developer profile info
   */
  SAVE_NOTDEV_PROFILE_INFO: 'submit/save-notdev-profile-info',

  /**
   * Api to fetch not developer profile info
   */
  GET_NOTDEV_PROFILE_INFO: 'submit/get-notdev-profile-info',

  /**
   * Api to save resume file from flow
   */
  SAVE_RESUME_FILE: 'submit/save-resume',

  /**
   * Api to save other infos on resume page from flow
   */
  SAVE_RESUME_INFO: 'submit/save-resume-info',

  /**
   * Api to save other infos on resume page from flow
   */
  GET_RESUME_INFO: 'submit/get-resume-info',

  /**
   * Api to save taking home challenge option
   */
  SAVE_HOME_CHALLENGE_OPTION: 'submit/save-home-challenge-option',

  /**
   * Api to get redirect url to go uncompleted page.
   */
  GET_REDIRECT_URL: 'auth/get-redirect-url',

  /**
   * Api to get redirect url to go uncompleted page.
   */
  GET_MAIL_REDIRECT_URL: 'auth/get-mail-token-redirect-url',

  /**
   * Api to get base jobs and levels
   */
  GET_BASE_JOBS_INFO: 'submit/get-base-jobs-info',

  /**
   * Api to save jobs and levels
   */
  SAVE_JOBS_INFO: 'submit/save-jobs-info',

  /**
   * Api to get jobs and skills info for user
   */
  GET_JOBS_SKILLS_INFO: 'submit/get-jobs-skills-info',

  /**
   * Api to get jobs and levels
   */
  GET_BASE_SKILLS_INFO: 'submit/get-base-skills-info',

  /**
   * Api to save skills and levels
   */
  SAVE_SKILLS_INFO: 'submit/save-skills-info',

  /**
   * Api to get profile information
   */
  GET_PERSONAL_DATA: 'dashboard/get-personal-data',

  /**
   * Api to get all user profile data at once for new profile page
   */
  GET_ALL_USER_PROFILE_DATA: 'dashboard/get-all-user-profile-data',

  /**
   * Api to get all project screenshots for user projects
   */
  GET_USER_PROJECT_SCREENSHOTS: 'dashboard/get-user-project-screenshots',

  /**
 * Api to get all base profile data like skills, certifications and countries new profile page
 */
  GET_ALL_BASE_PROFILE_DATA: 'dashboard/get-all-base-profile-data',

  /**
  * Update developer availability
  */
  SAVE_DEVELOPER_AVAILABILITY: 'dashboard/save-developer-availability',

  /**
  * Save developer  cloud certificates
  */
   SAVE_DEVELOPER_CLOUD_CERTIFICATES_PARTIAL: 'cloud-certification/user-certificate-partial',

  /**
   * Update new developer availability answer
   */
  SAVE_NEW_AVAILABILITY_ANSWER: 'dashboard/save-dev-availability',

  /**
  * get developer availability statuses
  */
  GET_DEVELOPER_AVAILABILITY_STATUSES: 'dashboard/get-developer-availability-statuses',

  /**
   * Api to update profile informations
   */
  UPDATE_PROFILE_INFO: 'dashboard/update-profile-info',

  /**
   * Api to update profile data
   */
  UPDATE_PROFILE_DATA: 'dashboard/update-profile-data',

  /**
   * Api to save user information for new profile page
   */
  SAVE_PERSONAL_INFO: 'dashboard/save-personal-info',

  /**
   * Api to update user profile update date
   */
  UPDATE_PROFILE_UPDATE_DATE: 'dashboard/update-profile-update-date',

  /**
   * Api to get user profile update date
   */
  GET_PROFILE_UPDATE_DATE: 'dashboard/get-profile-update-date',

  /**
   * Api to get user compensation
   */
  GET_COMPENSATION: 'dashboard/get-compensation',

  /**
   * Api to get user recommended tests
   */
  GET_RECOMMENDED_TESTS: 'dashboard/get-recommended-tests',

  /**
   * Api to save resume file
   */
  SAVE_RESUME: 'dashboard/save-resume',

  /**
   * Api to get turing resume link
   */
  GET_TURING_RESUME_LINK: 'dashboard/get-turing-resume-link',

  /**
   * Api to save avatar file
   */
  SAVE_AVATAR: 'dashboard/save-avatar',

  /**
   * Api to delete avatar file
   */
  DELETE_AVATAR: 'dashboard/delete-avatar',

  /*
   * Api to get the file name of uploaded system design answer file
   */
  GET_SYSTEM_DESIGN_INFO: 'dashboard/get-system-design',

  /*
   * Api to save system design answer file
   */
  SAVE_SYSTEM_DESIGN_INFO: 'dashboard/save-system-design',

  /*
   * Api to get the file name of uploaded product design answer file
   */
  GET_PRODUCT_DESIGN_INFO: 'dashboard/get-product-design',

  /*
   * Api to save product design answer file
   */
  SAVE_PRODUCT_DESIGN_INFO: 'dashboard/save-product-design',

  /**
   * Api to upload machine learning answer file
   */
  UPLOAD_DESIGN_CHALLENGE_OPTION_SUBMISSION: 'challenge/upload-design-challenge',

  /**
   * Api to save experience information for new profile page
   */
  SAVE_EXPERIENCE_INFO: 'dashboard/save-experience',

  /**
   * Api to save project information for new profile page
   */
  SAVE_PROJECT_INFO: 'dashboard/save-project',

  /**
   * Api to upload project screenshot
   */
  UPLOAD_SCREENSHOT: 'dashboard/upload-screenshot',

  /**
   * Api to save projects changes
   */
  SAVE_PROJECTS_CHANGES: 'dashboard/save-projects-changes',

  /**
   * Api to save educations changes
   */
  SAVE_EDUCATION_CHANGES: 'dashboard/save-education-changes',

  /**
   * Api to save publications changes
   */
  SAVE_PUBLICATIONS_CHANGES: 'dashboard/save-publications-changes',

  /**
   * Api to save certifications changes
   */
  SAVE_CERTIFICATIONS_CHANGES: 'dashboard/save-certifications-changes',

  /**
   * Api to save cloud certification changes
   */
  SAVE_CLOUD_CERTIFICATION_CHANGES: 'cloud-certification/user-certificate',

  /**
   * Api to save education information for new profile page
   */
  SAVE_EDUCATION_INFO: 'dashboard/save-education',

  /**
   * Api to save certification & award information for new profile page
   */
  SAVE_CERTIFICATION_INFO: 'dashboard/save-certification',

  /**
   * Api to save tech skill information for new profile page
   */
  SAVE_TECHSKILLS_INFO: 'dashboard/save-technical-skills',

  /**
   * Api to save tech skill information for new profile page
   */
  UPDATE_DEVELOPER_SKILLS: 'dashboard/update-developer-skills',

  /**
   * Api to save publication information for new profile page
   */
  SAVE_PUBLICATION_INFO: 'dashboard/save-publication',

  /**
   * Api to update experience information for new profile page
   */
  UPDATE_EXPERIENCE_INFO: 'dashboard/edit-experience',

  /**
   * Api to update project information for new profile page
   */
  UPDATE_PROJECT_INFO: 'dashboard/edit-project',

  /**
   * Api to update education information for new profile page
   */
  UPDATE_EDUCATION_INFO: 'dashboard/edit-education',

  /**
   * Api to update certification & award information for new profile page
   */
  UPDATE_CERTIFICATION_INFO: 'dashboard/edit-certification',

  /**
   * Api to update tech skill information for new profile page
   */
  UPDATE_TECHSKILLS_INFO: 'dashboard/edit-technical-skills',

  /**
   * Api to update publication information for new profile page
   */
  UPDATE_PUBLICATION_INFO: 'dashboard/edit-publication',

  /**
   * Api to delete experience information for new profile page
   */
  DELETE_EXPERIENCE_INFO: 'dashboard/delete-experience',

  /**
   * Api to delete project information for new profile page
   */
  DELETE_PROJECT_INFO: 'dashboard/delete-project',

  /**
   * Api to delete education information for new profile page
   */
  DELETE_EDUCATION_INFO: 'dashboard/delete-education',

  /**
   * Api to delete certification & award information for new profile page
   */
  DELETE_CERTIFICATION_INFO: 'dashboard/delete-certification',

  /**
   * Api to delete tech skill information for new profile page
   */
  DELETE_TECHSKILLS_INFO: 'dashboard/delete-technical-skills',

  /**
   * Api to delete publication information for new profile page
   */
  DELETE_PUBLICATION_INFO: 'dashboard/delete-publication',

  /**
   * Api to get base skills and levels
   */
  GET_BASE_ALL_SKILLS_INFO: 'submit/get-base-all-skills-info',

  /*
   * Api to submit linkedin data
   */
  SAVE_LINKEDIN_DATA: 'social-auth/linkedin/save',

  /**
   * Api to submit linkedin account status
   */
  SAVE_LINKEDIN_STATUS: 'social-auth/linkedin/status',

  /**
   * Api to submit linkedin account status
   */
  REDIRECT_LINKEDIN_OAUTH: 'social-auth/linkedin/oauth',

  /**
   * Api to submit linkedin account status
   */
  REDIRECT_LINKEDIN_CALLBACK: 'social-auth/linkedin/callback',

  /**
   * Api to change password
   */
  CHANGE_PASSWORD: 'dashboard/change-password',

  /**
   * Api to change email
   */
  CHANGE_EMAIL: 'dashboard/change-email',

  /**
   * Api to get account informations
   */
  GET_ACCOUNT_INFO: 'dashboard/get-account-info',

  /**
   * Api to get test result
   */
  GET_TEST_RESULT: 'dashboard/get-test-result',

  /**
   * Api to get contact list
   */
  GET_CONTACT_LIST: 'general/get-contact-list',

  /*
   * Api to submit github data
   */
  SAVE_GITHUB_DATA: 'social-auth/github/save',
  /**
   * Api to get contact list
   */
  SEND_FORGOT_PASS_EMAIL: 'cli/send-forgot-pass',

  /**
   * Api to reset password
   */
  RESET_PASS: 'auth/reset-pass',

  /**
   * Api to reset password
   */
  GET_EMAIL_FROM_TOKEN: 'auth/email-from-token',

  /**
   * Api to unsubscribe
   */
  UNSUBSCRIBE: 'auth/unsubscribe',

  /**
   * Api to unsubscribe from turing email
   */
  UNSUBSCRIBE_TURING_EMAIL: 'auth/unsubscribe-turing-email',

  /**
   * Api to unsubscribe
   */
  LOGOUT: 'auth/logout',

  /**
   * Api to delete account
   */
  Delete_Account: 'dashboard/delete-account',

  /**
   * Api to save mail list
   */
  SAVE_MAIL_LIST: 'mail/save-mail-list',

  /**
   * Api to delete account
   */
  MAIL_VALIDATION: 'mail/validation',

  /**
   * Api to get fake emails
   */
  GET_FAKE_EMAILS: 'mail/get-fake-emails',

  /**
   * Api to delete account
   */
  GET_ALL_EMAILS: 'mail/get-all-emails',

  /**
   * Api to delete account
   */
  MAIL_SEND_TEST_EMAIL: 'mail/send-test-email',

  /**
   * Api to delete account
   */
  MAIL_SEND_EMAIL: 'mail/send-email',

  /**
   * Api to get visit history
   */
  GET_VISIT_HISTORY: 'general/get-visit-history',

  /**
   * Api to get selected test language name
   */
  GET_SELECTED_LANGUAGE: 'submit/get-selected-language',

  /**
   * Api to login
   */
  SIGNIN_ADMIN: 'auth/admin/login',

  /**
   * Social Auth Google Login Api
   */
  SIGNIN_ADMIN_SSO: 'social-auth/google/adminlogin',

  /**
   * Api to get challenge type
   */
  GET_CHALLENGE_TYPE: 'dashboard/get_challenge_type',

  /**
   * Api to get host link
   */
  GET_CHALLENGE_Info: 'dashboard/get_challenge_info',

  /**
   * Api to get host link
   */
  GET_HOST_LINK: 'dashboard/get_host_link',

  /**
   * Api to get host link
   */
  GET_AUTOHOST_BUILD_STATUS: 'dashboard/get_autohost_build_status',

  /**
   * Api to get challenge list
   */
  GET_CHALLENGE_LIST: 'general/get-challenge-list',

  /**
   * Api to get challenge log list
   */
  GET_CHALLENGE_LOG_LIST: 'general/get-challenge-log-list',

  /**
   * Api to get challenge top errors
   */
  GET_CHALLENGE_TOP_ERRORS: 'general/get-challenge-top-errors',

  /**
   * Api to send email to turing manager
   */
  SEND_EMAIL_TO_MANAGER: 'dashboard/send-email-to-manager',

  /**
   * Api to unlock challenge
   */
  UNLOCK_CHALLENGE: 'submit/unlock-challenge',

  /**
   * Api to save show description track value
   */
  SAVE_SHOW_DESCRIPTION_TRACK: 'dashboard/save-show-description-track',

  /**
   * Api to save challenge visit
   */
  SAVE_FRONTEND_CHALLENGE_VISIT: 'visitor/saveVisitorInfo',

  /**
   * Api to save landing page visit
   */
  SAVE_INVITATION_RESPONSE: 'visitor/saveInvitationResponse',

  /**
   * Api to send invitaion email
   */
  SEND_INVITATION_EMAIL: 'visitor/sendInvitaionEmail',

  /**
   * Api to get send grid Statistics
   */
  GET_SEND_GRID_STATS: 'stats/get-sendgrid',

  /**
   * Api to get frontend challenge Statistics
   */
  GET_CHALLENGE_STATS: 'stats/get-challenge',

  /**
   * Api to get invitation emails Statistics
   */
  GET_INVITATION_EMAIL_STATS: 'stats/get-invitationEmail',

  /**
   * Api to get sendgrid stats for devices
   */
  GET_SEND_GRID_DEVICE_STATS: 'stats/get-sendGridDevices',

  /**
   * Api to get sendgrid stats for mailbox providers
   */
  GET_SEND_GRID_MAILBOX_PROVIDER_STATS: 'stats/get-sendGridMailboxProvider',

  /**
   * Api to get sendgrid stats for all mailbox providers
   */
  GET_SEND_GRID_ALL_MAILBOX_PROVIDERS_STATS: 'stats/get-sendGridAllMailboxProviders',

  /**
   * Api to get sendgrid stats for all mailbox providers
   */
  GET_MAILGUN_STATS: 'stats/get-mailgun',

  /**
   * Api to get sendgrid stats for devices
   */
  GET_MAILGUN_DEVICE_STATS: 'stats/get-mailgunDevices',

  /**
   * Api to get sendgrid stats for devices
   */
  GET_MAILGUN_MAILBOX_PROVIDER_STATS: 'stats/get-mailgunMailboxProvider',

  /**
   * Api to save click analytics
   */
  SAVE_CLICK_ANALYTICS: 'visitor/saveClickAnalytics',

  /**
   * Api to get Turing stats for devices
   */
  GET_TURING_DEVICE_STATS: 'stats/get-turingDevices',

  /**
   * Api to check admin token
   */
  CHECK_ADMIN_TOKEN_VALIDATION: 'auth/admin/check_validation',

  /**
   * Api to upload cover image
   */
  UPLOAD_COVER_IMAGE: 'challenge/upload-cover-image',
  UPLOAD_ICON_IMAGE: 'challenge/upload-icon-image',

  /**
   * Create service account
   */
  CREATE_SERVICE_ACCOUNT: 'user/admin/create-service-account',
  /**
   * Get service accounts
   */
  GET_SERVICE_ACCOUNTS: 'user/admin/get-service-accounts',
  /**
   * Delete service account
   */
  DELETE_SERVICE_ACCOUNT: 'user/admin/delete-service-account',

  /**
   * Get user signup funnel info
   */
  GET_USER_SIGNUP_FUNNEL_INFO: 'submit/get-user-signup-funnel-info',

  /**
   *  Get developer skills info
   */

  GET_DEVELOPER_SKILL_INFO: 'user/get-developer-skill-info',

  SAVE_NOT_ENOUGH_SKILL_EXP: 'user/save-not-enough-experience',

  UPDATE_USER_DEMAND_STACK_SKILLS: 'user/update-user-demand-stack-skills',


  /**
   * Get list of all cloud certification
   */

  GET_CLOUD_CERTIFICATION_LIST: 'cloud-certification/user-certification-type',
  GET_USER_CLOUD_CERTIFICATES: 'cloud-certification/user-certificate',


  UPDATE_USER_SKILL_DATA: 'user/update-user-skill-data',
  ADD_USER_SKILL_DATA: 'user/add-user-skill-data',
  DELETE_USER_SKILL_DATA: 'user/delete-user-skill-data',

  GET_CURRENCY_RATE_LIST: 'dashboard/get-currency-rate-list',

  GET_PARSED_RESUME_DATA: 'dashboard/get-parsed-resume-data',

  GET_USER_AVAILABILITY: 'dashboard/get-user-availability',
  GET_USER_AVAILABILITY_V2: 'dashboard/fetch-dev-availability',
  GET_USER_SIDEBAR_DATA: 'dashboard/fetch-sidebar-data',
  GET_SKILLS_CERTIFICATION: 'common-user/',

  LOGGING_ENDPOINT: 'logging/postLog',
  LOGGING_ENDPOINT_DB: 'logging/postLog/db',
  LOGGING_ENDPOINT_DB_: 'logging/postLog/db_',
  ADD_NEW_ACCOUNT_SETTING: 'onboarding/update-account-setting',

  /**
   * Onboarding route
   */
  ONBOARDING: 'onboarding',
  SEND_EMAIL_INVITE: 'send_invites',
  GET_REFERRAL_CODE: 'get-refferal-code',

  IP2COUNTRY: 'landingpage/ip2country',

  /**
   * RESOURCES
   */
  GET_RESOURCES: 'cms/get-resources',
  CREATE_RESOURCE: 'cms/admin/create-resource',
  UPDATE_RESOURCE: 'cms/admin/update-resource',
  DELETE_RESOURCE: 'cms/admin/delete-resource',
  UPLOAD_IMAGE: 'cms/admin/upload-image',

  /**
   * ClientInformation
   */
  GET_CLIENT_INFORMATION: 'client-information',
  GET_CLIENT_INFORMATION_BY_IP: 'client-information-by-ip',

  REPLACE_SAVED_RESUME_DATA: 'dashboard/replace-saved-resume-data',

  /**
   * API for fetching and storing cosine similarity for job-dev pair
   */
  RESUME_JOB_POST_PROCESS: 'dashboard/resume-job-postprocess',
  SYNC_LINKEDIN_DATA : 'onboarding/sync-linkedin-data',
  LAST_VISITED_STEP : 'onboarding/last-visited-step',
  
  GET_VETSMITH_FLOW_DATA: 'vetsmith/load-data',
  MARK_VETSMITH_STEP_COMPLETE_FOR_DEV: 'vetsmith/mark-step-complete-for-dev',

  /**
   * External sourcing
   */
  SAVE_EXTERNAL_SOURCING_DATA: 'invite',
  GET_JOB_INFO: 'invite/jobInfo',
  SAVE_PAGE_OPEN_LOG: 'invite/open',

  /**
   * Fast track
   */
  UPDATE_FAST_TRACK_STATUS: 'fast-track-flow-developer/update',

  /**
   * Vetsmith APIs
   */
  UPLOAD_DEVELOPER_FILES: 'vetsmith/upload-developer-file',
  JOB_APPLY: 'job-apply',

  OPEN_GB_FLAG: 'darkly/open',
  FETCH_LANGUAGE_PROFICIENCY_TYPE_AND_LEVELS: 'languages/proficiency-type-and-levels',
  FETCH_DEVELOPER_LANGUAGE_PROFICIENCY : 'developer/languages/proficiency',
};

export default ApiConstant;
